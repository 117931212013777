import { useState, useEffect } from 'react';
import './App.css';
import { Grid, IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';

import CustomButton from '@/components/inputs/CustomButton';
import CustomTextField from '@/components/inputs/CustomTextField';
import useValues from '@/components/dataHandling/hooks/useValues';
import DesignProvider from '@/components/layout/DesignProvider';

const defaultValues = {
  name: '',
  link: '',
};

const Actions = () => {
  const { values, handleChange } = useValues({ defaultValues });
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    const storedName = localStorage.getItem('name') || '';
    const storedLink = localStorage.getItem('link') || '';
    handleChange({ values: { name: storedName, link: storedLink } });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (refreshCounter === 5) {
      localStorage.setItem('name', '');
      localStorage.setItem('link', '');
      handleChange({ values: { name: '', link: '' } });
    } else if (refreshCounter > 0) {
      setTimeout(() => {
        if (mounted) { setRefreshCounter(0); }
      }, 1000);
    }
    return () => {
      mounted = false;
    };
  }, [refreshCounter]);

  const saveData = () => {
    localStorage.setItem('name', name);
    localStorage.setItem('link', link);
    handleChange({ values: { name, link } });
  };

  if (!values.name || !values.link) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomTextField label="Name" name="name" value={name} onChange={({ value }) => setName(value)} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField label="Link" name="link" value={link} onChange={({ value }) => setLink(value)} />
        </Grid>
        <CustomButton fullWidth onClick={saveData}>ЗАПАЗИ</CustomButton>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <a href={values.link} target="_blank" rel="noreferrer">
          <CustomButton
            fullWidth
            style={{
              marginTop: 60,
              minHeight: '50vh',
            }}
            size="large"
          >
            {values.name}
          </CustomButton>
        </a>
      </Grid>
      <IconButton
        style={{
          position: 'fixed',
          top: 10,
          left: 10,
        }}
        onClick={() => setRefreshCounter(refreshCounter + 1)}
        size="small"
      >
        <Refresh />
      </IconButton>
    </Grid>
  );
};

const App = () => (
  <DesignProvider>
    <Actions />
  </DesignProvider>
);

export default App;
